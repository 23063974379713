import {
  defineNuxtRouteMiddleware,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtRouteMiddleware(() => {
  const { isLoading, isFundInvestor } = toRefs(useAuthStore());

  if (!isLoading.value && !isFundInvestor.value) {
    return navigateTo({ path: '/aanbod' });
  }
});
